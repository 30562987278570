import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Menu, Select } from 'antd';
import ListSummary from "./ListSummary";
import ListSummary1 from "./ListSummary1";
import ListSummary2 from "./ListSummary2";
import './menu.css'

function Resumes() {
    const navigate = useNavigate();
    const [selectedKey, setSelectedKey] = useState('1')

    useEffect(() => {
        localStorage.setItem('title', 'Resumos');
        localStorage.setItem('type', '2');
    }, [])

    const handleMenuClick = e => {
        setSelectedKey(e.key);
    }

    return (
        // <div className='flex flex-col gap-4'>
        <div
            class = "menu"
        >
            <Menu
                mode="vertical"
                className='rounded-lg pb-1'
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
                style={{width: 200}}
            >
                <Menu.Item key="1" className='hover:bg-blue-500'>
                    Pesquisa Científica
                </Menu.Item>
                <Menu.Item key="2" className='hover:bg-blue-500'>
                    Caso Clínico
                </Menu.Item>
                <Menu.Item key="3" className='hover:bg-blue-500'>
                    Avaliação programática
                </Menu.Item>
            </Menu>
            {/*<div className="flex flex-col gap-2 bg-white rounded-lg p-4">*/}
            <div class="right-side">
                {selectedKey == '1' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <ListSummary/>
                    </div>
                }
                {selectedKey == '2' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <ListSummary1/>
                    </div>
                }
                {selectedKey == '3' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <ListSummary2/>
                    </div>
                }
            </div>
        </div>
    );
}

export default Resumes;