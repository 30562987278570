import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from 'react-query';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Login from './Pages/Account/signin';
import MainLayout from './Layouts/MainLayout';
import Menu from './Pages/Menu';
import ProfileLayout from './Pages/Account/profile';
import Recover from './Pages/Account/recoverpassword';
import Resumes from './Pages/SummaryManagment/Resumes';
import HistResumes from './Pages/SummaryManagment/HistResumes';

const router = createBrowserRouter([
  {
    path: '/account/login',
    element: <Login />
  },
  {
    path: '/account/resetpass',
    element: <Recover />
  },
  {
    path: '/',
    element: <MainLayout/>,
    children:[
      {
        path:'/',
        element: <Menu />,
      },
      {
        path:'/resumos',
        element: <Resumes/>,
      },
      {
        path:'/historico',
        element: <HistResumes/>,
      },
      {
        path:'/perfil',
        element: <ProfileLayout />,
      }
    ]
  },
]);

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
