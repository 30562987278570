import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus, FaEye, FaDownload, FaArrowRight, FaRegComment } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Form, Space, Table, Divider, notification, Modal, Tag, Row, Col, Menu, Select } from 'antd';
import {CheckOutlined, UserAddOutlined, EditOutlined, EyeOutlined} from '@ant-design/icons';
import NovoUser from '../../Components/User/NovoUser';
import EditarUser from '../../Components/User/EditarUser';
import { el, fi } from 'date-fns/locale';
import dayJS from 'dayjs';
import { set } from 'date-fns';
import { ComponentToPrint } from '../../Components/Form/print';
import ReactToPrint from 'react-to-print';
import Modelo1 from '../../Components/Form/modelo1';
import { MdOutlineGrade } from "react-icons/md";

const { Option } = Select;

function ListSummary1() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [markedPage, setMarkedPage] = useState(true);
    const [modalKey, setModalKey] = useState(Date.now());
    const [modalView, setModalView] = useState(false);
    const [modalView1, setModalView1] = useState(false);
    const [modalEdit, setModalEdit] = useState(false);
    const [modalRev, setModalRev] = useState(false);
    const [modalEditRev, setModalEditRev] = useState(false);
    const [modalWork, setModalWork] = useState(false);
    const [modalFinal, setModalFinal] = useState(false);
    const [selected, setSelected] = useState({});
    const [form] = Form.useForm();
    const [selectedKey, setSelectedKey] = useState('1')
    const [selectedKey2, setSelectedKey2] = useState('1')
    const [reviewers, setReviewers] = useState([]);
    const [selectedReviewer, setSelectedReviewer] = useState([]);
    const printRef = useRef();
    const [comment, setComment] = useState('');
    const [type, setType] = useState(null);
    const [category, setCategory] = useState([]);
    const [subCategory, setSubCategory] = useState([]);
    const [avaliation, setAvaliation] = useState({
        tipoApresentacao: null,
        categoria: null,
        subCategoria: null,
        comment: null,
        type: null,
        status: null,
    });

    //Modals para aprovar e reprovar
    const [modalAprove, setModalAprove] = useState(false);
    const [modalReprove, setModalReprove] = useState(false);

    const [searchValue, setSearchValue] = useState({
        nid: '',
        nome: '',
        contacto: '',
    });

    const [status, setStatus] = useState(null)

    useEffect(() => {
        localStorage.setItem('title', 'Resumos');
        localStorage.setItem('type', '2');
        getStatus().then((e) => {
            getInVal();
            getCategory();
            getSubCategory();
        });
    }, [])

    const goView = (record) => {
        try {
            setSelected(record);
            setModalKey(Date.now());
            setComment('');
        } finally {
            setModalView(true);
        }
    }

    const goView1 = (record) => {
        try {
            setSelected(record);
            setModalKey(Date.now());
            setComment('');
        } finally {
            setModalView1(true);
        }
    }

    const goRev = (record) => {
        try {
            setSelectedReviewer([]);
            setSelected(record);
            form.setFieldsValue(record);
            setModalKey(Date.now());
        } finally {
            setModalRev(true);
        }
    }

    const goEditRev = (record) => {
        try {
            setSelectedReviewer(record.reviews.map(item => item.reviewer.id));
            setSelected(record);
            form.setFieldsValue(record);
            setModalKey(Date.now());
        } finally {
            setModalEditRev(true);
        }
    }

    const goEditRevDone = (record) => {
        try {
            console.log(record);
            setSelectedReviewer(record.reviews.map(item => item.reviewer.id));
            setSelected(record);
            form.setFieldsValue(record);
            setModalKey(Date.now());
        } finally {
            setModalEditRev(true);
        }
    }


    const getCategory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/category/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            console.log(response.data);
            setCategory(response.data);
        } catch (error) {
            setLoading(false);
        }
    }

    const getSubCategory = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/category/subcategory/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            setSubCategory(response.data);
        } catch (error) {
            setLoading(false);
        }
    }


    //Em Validacao
    const columnsMarked = [
        {
            title: "ID",
            dataIndex: "id",
            width: 90,
            key: "id",
        },
        {
            title: "Titulo do Resumo",
            dataIndex: "title",
        },
        {
            title: "Autor do Resumo",
            dataIndex: "author",
            render: (text, record) => (
                <p>{record.author.first_user_name} {record.author.last_user_name}</p>
            ),
        },
        {
            title: "Data de Submissão",
            dataIndex: "date",
            width: 160,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY ')}</p>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 130,
            render: (text, record) => (
                <div>
                    <Tag color={record.status === "Em validação" ? "orange" : "green"} key={record.status}>
                        {record.status.name}
                    </Tag>
                </div>
            ),
        },
        {
            title: "Acções",
            key: "actions",
            width: 90,
            align: "center",
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView(record)}>
                        <CheckOutlined className='text-white' size={35} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getStatus = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resume/status/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            setStatus(response.data);
            console.log(status)
            console.log("status loaded");
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao carregar estados de resumos'
            });
        } finally {
            setLoading(false);
        }
    }

    const getInVal = async () => {
        console.log("outer")
        console.log(status)
        if (status !== null) {
            const sid = status.filter((item) => item.name === "Em validação")
            if (sid !== undefined) {
                try {
                    setLoading(true);
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resume/clinical/`, {
                        headers: {
                            Authorization: `Token ${sessionStorage.getItem('token')}`
                        },
                        params: {
                            status: sid[0].id
                        }
                    });
                    console.log(response.data);
                    setDataMarked(response.data);
                } catch (error) {
                    setLoading(false);
                    notification.error({
                        message: 'Erro',
                        description: 'Falha ao carregar resumos em validação'
                    });
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            }
        }
    }

    const putValidate = async () => {
        if (status !== null) {
            const sid = status.filter((item) => item.name === "Validado")
            if (sid !== undefined) {
                try {
                    setLoading(true);
                    const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/resume/clinical/${selected.id}/status/`, {
                        status: sid[0].id
                    }, {
                        headers: {
                            Authorization: `Token ${sessionStorage.getItem('token')}`
                        }
                    });
                    notification.success({
                        message: 'Sucesso!',
                        description: 'Resumo validado!'
                    })
                    setModalView(false);
                    setModalAprove(false);
                    getInVal();
                } catch (error) {
                    setLoading(false);
                    notification.error({
                        message: 'Erro',
                        description: 'Falha ao Validar Resumo'
                    });
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            }
        }
    }

    const handleInvalidate = async () => {
        if (comment.length > 3) {
            if (status !== null) {
                const sid = status.filter((item) => item.name === "Invalidado")
                if (sid !== undefined) {
                    try {
                        setLoading(true);
                        const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/resume/clinical/${selected.id}/status/`, {
                            status: sid[0].id,
                            comment: comment
                        }, {
                            headers: {
                                Authorization: `Token ${sessionStorage.getItem('token')}`
                            }
                        });
                        notification.success({
                            message: 'Sucesso!',
                            description: 'Resumo invalidado!'
                        })
                        setModalView(false);
                        setModalReprove(false);
                        getInVal();
                    } catch (error) {
                        setLoading(false);
                        notification.error({
                            message: 'Erro',
                            description: 'Falha ao invalidar Resumo'
                        });
                        console.log(error);
                    } finally {
                        setLoading(false);
                    }
                }
            }
        } else {
            notification.error({
                message: 'Erro',
                description: 'Por favor insira um motivo para invalidar o resumo'
            });
        }
    }

    const [dataMarked, setDataMarked] = useState([]);

    //Validados
    const columnsVal = [
        {
            title: "ID",
            dataIndex: "id",
            width: 90,
            key: "id",
        },
        {
            title: "Titulo do Resumo",
            dataIndex: "title",
        },
        {
            title: "Autor do Resumo",
            dataIndex: "author",
            render: (text, record) => (
                <p>{record.author.first_user_name} {record.author.last_user_name}</p>
            ),
        },
        {
            title: "Data de Validação",
            dataIndex: "date",
            width: 160,
            render: (text, record) => (
                <p>{dayJS(record.updated_at).format('DD/MM/YYYY ')}</p>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 130,
            render: (text, record) => (
                <div>
                    <Tag color={record.status.name === "Validado" ? "blue" : "green"} key={record.status.id}>
                        {record.status.name}
                    </Tag>
                </div>
            ),
        },
        {
            title: "Acções",
            key: "actions",
            width: 90,
            align: "center",
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView1(record)}>
                        <EyeOutlined className='text-white' size={35} />
                    </Button>
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goRev(record)}>
                        <UserAddOutlined className='text-white' size={35} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getVal = async () => {
        if (status !== null) {
            const sid = status.filter((item) => item.name === "Validado")
            if (sid !== undefined) {
                try {
                    setLoading(true);
                    const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resume/clinical/`, {
                        headers: {
                            Authorization: `Token ${sessionStorage.getItem('token')}`
                        },
                        params: {
                            status: sid[0].id
                        }
                    });
                    console.log(response.data);
                    setDataVal(response.data);
                } catch (error) {
                    setLoading(false);
                    notification.error({
                        message: 'Erro',
                        description: 'Falha ao carregar resumos validados'
                    });
                    console.log(error);
                } finally {
                    setLoading(false);
                }
            } else {
                setDataVal([]);
            }
        }
    }

    const getReviewers = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/reviewer/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                },
            });
            setReviewers(response.data);
            console.log(response.data);
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao carregar revisores'
            });
            console.log(error);
        }
    }

    const [dataVal, setDataVal] = useState([])

    const alocarReviewer = async () => {
        try {
            setLoading(true);
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/resume/clinical/${selected.id}/review/`, {
                reviewer: selectedReviewer
            }, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            notification.success({
                message: 'Sucesso!',
                description: 'Alocado!'
            })
            setModalRev(false);
            setSelectedReviewer([]);
            getVal();
            getRevDone();
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao alocar revisor'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const reAlocarReviewer = async () => {
        console.log(selectedReviewer);
        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/resume/clinical/${selected.id}/review/`, {
                reviewer: selectedReviewer
            }, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            notification.success({
                message: 'Sucesso!',
                description: 'Realocado!'
            })
            setModalRev(false);
            setSelectedReviewer([]);
            getRevDone();
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao alocar revisor'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }


    //Em Revisao
    const columnsRev = [
        {
            title: "ID",
            dataIndex: "id",
            width: 90,
            key: "id",
        },
        {
            title: "Titulo do Resumo",
            dataIndex: "title",
        },
        {
            title: "Autor do Resumo",
            dataIndex: "author",
            render: (text, record) => (
                <p>{record.author?.first_user_name} {record.author?.last_user_name}</p>
            ),
        },
        {
            title: "Revisor do Resumo",
            dataIndex: "revisor",
            render: (text, record) => (
                <p>{record.reviews?.map(item => item.reviewer?.first_user_name + ' ' + item.reviewer?.last_user_name).join(', ')}</p>
            ),
        },
        {
            title: "Data da Alocação",
            dataIndex: "date",
            width: 160,
            render: (text, record) => (
                <p>{dayJS(record.updated_at).format('DD/MM/YYYY ')}</p>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 130,
            render: (text, record) => (
                <div>
                    <Tag color={record.status.name === "Em Revisão" ? "yellow" : "green"} key={record.status}>
                        {record.status.name}
                    </Tag>
                </div>
            ),
        },
        {
            title: "Acções",
            key: "actions",
            width: 90,
            align: "center",
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView1(record)}>
                        <EyeOutlined className='text-white' size={35} />
                    </Button>
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goEditRev(record)}>
                        <EditOutlined className='text-white' size={35} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getRev = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/secretary/resume/clinical/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                },
                params: {
                    reviewed: 0
                }
            });
            console.log(response.data);
            setDataRev(response.data);
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao carregar resumos em revisão'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const transformData = (abstracts) => {
        const abstractsWithReviewers = {};

        // Iterar sobre cada item na lista de abstracts
        abstracts.forEach(item => {
            const abstractId = item.abstract.id;

            // Se o abstract ainda não estiver na lista, adicione-o
            if (!abstractsWithReviewers[abstractId]) {
                abstractsWithReviewers[abstractId] = {
                    ...item.abstract,
                    reviewers: [item.reviewer]
                };
            } else {
                // Se o abstract já estiver na lista, adicione o revisor ao array de revisores
                abstractsWithReviewers[abstractId].reviewers.push(item.reviewer);
            }
        });
        console.log(Object.values(abstractsWithReviewers));
        // Transforme o objeto em um array de abstracts com revisores agrupados
        return Object.values(abstractsWithReviewers);
    };

    const [dataRev, setDataRev] = useState([])

    //Revisados
    const columnsRevDone = [
        {
            title: "ID",
            dataIndex: "id",
            width: 90,
            key: "id",
        },
        {
            title: "Titulo do Resumo",
            dataIndex: "title",
        },
        {
            title: "Autor do Resumo",
            dataIndex: "author",
            render: (text, record) => (
                <p>{record.author?.first_user_name} {record.author?.last_user_name}</p>
            ),
        },
        {
            title: "Revisor(es) do Resumo",
            dataIndex: "revisor",
            render: (text, record) => (
                <p>{record.reviews?.map(item => item.reviewer?.first_user_name + ' ' + item.reviewer?.last_user_name).join(', ')}</p>
            ),
        },
        {
            title: "Data da Revissão",
            dataIndex: "date",
            width: 160,
            render: (text, record) => (
                <p>{dayJS(record.updated_at).format('DD/MM/YYYY ')}</p>
            ),
        },
        {
            title: "Acções",
            key: "actions",
            width: 90,
            align: "center",
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView1(record)}>
                        <EyeOutlined className='text-white' size={35} />
                    </Button>
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goWork(record)}>
                        <EditOutlined className='text-white' size={35} />
                    </Button>
                    <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goEditRevDone(record)}>
                        <UserAddOutlined className='text-white' size={35} />
                    </Button>
                </Space>
            ),
        },
    ];

    const getRevDone = async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/user/secretary/resume/clinical/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                },
                params: {
                    reviewed: 1
                }
            });
            console.log(response.data);
            setDataRevDone(response.data.filter(item => item.status.name === 'Em Revisão'));
        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao carregar resumos'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const [dataRevDone, setDataRevDone] = useState([])

    const goWork = (record) => {
        console.log(record);
        try {
            setAvaliation({
                tipoApresentacao: null,
                categoria: null,
                subCategoria: null,
                comment: null,
                type: null,
                status: null,
            });
            setSelectedKey2('1')
            setSelected(record);
            setModalKey(Date.now());
        } finally {
            setModalWork(true);
        }
    }

    const filtered = (data) => {
        return data.filter((item) => {
            return (
                item.title.toLowerCase().includes(searchValue.nome.toLowerCase()) ||
                `${item.author.first_user_name} ${item.author.last_user_name}`.toLowerCase().includes(searchValue.nome.toLowerCase())
            );
        });
    }

    const countWords = (text) => {
        return text?.split(' ').filter(word => word).length;
    }

    const handleMenuClick = e => {
        setSelectedKey(e.key);
        if (e.key == '1') {
            getInVal();
        } else if (e.key == '2') {
            getReviewers();
            getVal();
        } else if (e.key == '3') {
            getReviewers();
            getRev();
        } else if (e.key == '4') {
            getReviewers();
            getRevDone();
        }
    }

    const handleMenuClick2 = e => {
        setSelectedKey2(e.key)
    }

    const handleValidate = (status) => {
        try {
            avaliation.status = status;
            setAvaliation({ ...avaliation, status: status });
            setModalKey(Date.now());
            setAvaliation({ ...avaliation, type: status });
        } finally {
            setModalFinal(true);
        }
    }

    const confirmValidate = async () => {
        console.log(avaliation);
        try {
            setLoading(true);
            const response = await axios.put(`${process.env.REACT_APP_API_URL}/api/resume/clinical/${selected.id}/status/`, {
                status: avaliation.status,
                presentation: avaliation.tipoApresentacao,
                category: avaliation.categoria,
                subcategory: avaliation.subCategoria,
                comment: avaliation.comment
            }, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            notification.success({
                message: 'Sucesso!',
                description: 'Resumo Avaliado!'
            })
            setModalWork(false)
            setModalFinal(false);
            getRevDone();

        } catch (error) {
            setLoading(false);
            notification.error({
                message: 'Erro',
                description: 'Falha ao avaliar resumo'
            });
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className='flex flex-col gap-4'>
            <Menu
                mode="horizontal"
                className='rounded-lg pb-1'
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
            >
                <Menu.Item key="1" className='hover:bg-blue-500'>
                    Em Validação
                </Menu.Item>
                <Menu.Item key="2" className='hover:bg-blue-500'>
                    Validado
                </Menu.Item>
                <Menu.Item key="3" className='hover:bg-blue-500'>
                    Em Revisão
                </Menu.Item>
                <Menu.Item key="4" className='hover:bg-blue-500'>
                    Revistos
                </Menu.Item>
            </Menu>
            <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                <div className="flex flex-col items-center sm:flex-row gap-2">
                    <FaSearch />
                    <Input
                        placeholder="Pesquisa por Nome"
                        value={searchValue.nome}
                        className='sm:w-64'
                        onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
                    />
                </div>
                <Divider className='my-0' />
                {selectedKey == '1' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='small'
                            columns={columnsMarked}
                            dataSource={filtered(dataMarked)}
                            loading={loading}
                            pagination={{ pageSize: 5 }}
                        />
                    </div>
                }
                {selectedKey == '2' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='small'
                            columns={columnsVal}
                            dataSource={filtered(dataVal)}
                            loading={loading}
                            pagination={{ pageSize: 5 }}
                        />
                    </div>
                }
                {selectedKey == '3' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='small'
                            columns={columnsRev}
                            dataSource={filtered(dataRev)}
                            loading={loading}
                            pagination={{ pageSize: 5 }}
                        />
                    </div>
                }
                {selectedKey == '4' &&
                    <div style={{ maxWidth: '100%', overflowX: 'auto' }}>
                        <Table
                            size='small'
                            columns={columnsRevDone}
                            dataSource={filtered(dataRevDone)}
                            loading={loading}
                            pagination={{ pageSize: 5 }}
                        />
                    </div>
                }
                <Modal
                    title={"Avaliar Resumo"}
                    visible={modalWork}
                    onCancel={() => setModalWork(false)}
                    footer={null}
                    width={1000}
                >
                    <div className='w-full'>
                        {selectedKey2 == '1' &&
                            <div className='w-full bg-white rounded-lg p-4'>
                                <div className='w-full flex flex-col gap-2'>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Titulo</p>
                                        <p className='text-sm rounded-md'>{selected?.title}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.title || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Tipo de Apresentação</p>
                                        <p className='text-sm rounded-md'>{selected.presentation?.name}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.presentation == 1 ? 'Oral' : item.presentation == 2 ? 'Poster' : "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Tipo de Categoria</p>
                                        <p className='text-sm rounded-md'>{selected.category?.name}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.category || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Tipo de Subcategoria</p>
                                        <p className='text-sm rounded-md'>{selected.subcategory?.name}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.subcategory || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Aprovação ética</p>
                                        <p className='text-sm rounded-md' style={{border: 'none'}}>
                                            {selected.ethic ? <Tag>Sim</Tag> : <Tag>Não</Tag>}
                                        </p>
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Introdução</p>
                                        <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.introduction}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.introduction || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Resumo do caso</p>
                                        <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.resume}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.resume || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Discussão</p>
                                        <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.discussion}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.result || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Conclusão</p>
                                        <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.conclusion}</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.conclusion || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Palavras Chave</p>
                                        <p className='text-sm rounded-md' style={{border: 'none'}}>{selected?.keyword?.map((item, index) =>
                                            <Tag key={item.id}>{item.name}</Tag>)} </p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.keyword || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Comentário Geral</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item.comment || "---Não Revisto---"}
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Decisão dos Revisores</p>
                                        {selected.reviews?.map((item, index) => (
                                            <div key={index} className='w-full flex flex-col gap-2'>
                                                <p className='text-sm mb-2 flex items-center'>
                                                    <FaRegComment className='mr-2'/>
                                                    <i>{item.reviewer?.first_user_name} {item.reviewer?.last_user_name}</i>: {item?.status?.name}  •  <MdOutlineGrade className='mr-2' style={{ width: '22px', height: '22px' }}/>
                                                    : {item?.classification} Pontos
                                                </p>
                                            </div>
                                        ))}
                                    </div>
                                    <Divider className='my-0'/>
                                    <Form
                                        layout='vertical'
                                    >
                                        <Row gutter={16}>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Apresentação"
                                                    rules={[
                                                        {required: true, message: 'Por favor Seleccione o tipo de apresentação'},
                                                    ]}
                                                    className='mb-2'
                                                >
                                                    <Select
                                                        placeholder="Seleccione o tipo de apresentação"
                                                        allowClear
                                                        value={avaliation.tipoApresentacao}
                                                        onChange={(value) => setAvaliation({...avaliation, tipoApresentacao: value})}
                                                    >
                                                        <Option value={1}>Oral</Option>
                                                        <Option value={2}>Poster</Option>
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Categoria"
                                                    rules={[
                                                        {required: true, message: 'Por favor Seleccione a categoria'},
                                                    ]}
                                                    className='mb-2'
                                                >
                                                    <Select
                                                        placeholder="Seleccione a categoria"
                                                        allowClear
                                                        value={avaliation.categoria}
                                                        onChange={(value) => setAvaliation({...avaliation, categoria: value})}
                                                    >
                                                        {category.map((item, index) => (
                                                            <Option key={index} value={item.id}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={8}>
                                                <Form.Item
                                                    label="Sub-Categoria"
                                                    rules={[
                                                        {required: true, message: 'Por favor Seleccione a sub-categoria'},
                                                    ]}
                                                    className='mb-2'
                                                >
                                                    <Select
                                                        placeholder="Seleccione a sub-categoria"
                                                        allowClear
                                                        value={avaliation.subCategoria}
                                                        onChange={(value) => setAvaliation({...avaliation, subCategoria: value})}
                                                    >
                                                        {subCategory.filter(item => item.category.id == avaliation.categoria).map((item, index) => (
                                                            <Option key={index} value={item.id}>{item.name}</Option>
                                                        ))}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                                <div className='w-full flex flex-row gap-4 justify-between items-center mt-4'>
                                    <Button
                                        htmlType="submit"
                                        loading={loading}
                                        onClick={() => handleValidate(3)}
                                        className='ml-auto font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                                    >
                                        Reprovar
                                    </Button>
                                    <Button
                                        htmlType="submit"
                                        loading={loading}
                                        onClick={() => handleValidate(5)}
                                        className='text-white bg-blue-600 font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                                    >
                                        Aprovar
                                    </Button>
                                </div>
                            </div>
                        }
                    </div>
                </Modal>
                <Modal
                    title={avaliation.type == 5 ? "Aprovar Resumo" : "Reprovar Resumo"}
                    centered
                    visible={modalFinal}
                    okButtonProps={{ style: { backgroundColor: '#00728a', borderColor: '#00728a' } }}
                    okText="Confirmar"
                    onOk={() => confirmValidate()}
                    onCancel={() => setModalFinal(false)}
                >
                    <Form
                        layout='vertical'
                    >
                        {avaliation.type == 5 && <>
                            <Form.Item
                                label="Tipo de Aprovação"
                                className='mb-2'
                            >
                                <Select
                                    placeholder="Seleccione"
                                    allowClear
                                    className='w-full'
                                    value={avaliation.status}
                                    onChange={(e) => setAvaliation({ ...avaliation, status: e })}
                                >
                                    <Option value={5}>Sem Modifições</Option>
                                    <Option value={6}>Com Modifições Ligeiras</Option>
                                </Select>
                            </Form.Item>
                        </>}

                        <Form.Item
                            label="Comentário"
                            className='mb-2'
                        >
                            <Input.TextArea
                                placeholder='Insira o Comentário'
                                value={avaliation.comment}
                                onChange={(e) => setAvaliation({ ...avaliation, comment: e.target.value })}
                            />
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal
                    title={"Resumo"}
                    visible={modalView}
                    onCancel={() => setModalView(false)}
                    footer={null}
                    width={800}
                >
                    <div className='w-full'>
                        <div className='w-full flex flex-row justify-between items-center mb-0'>
                            <p className='text-sm font-bold mb-2'>Pré-Visualização</p>
                            <ReactToPrint
                                trigger={() => (
                                    <Button className='flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => setModalView(false)}>
                                        <FaDownload className='mr-2' />Baixar
                                    </Button>
                                )}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='hidden'>
                            <ComponentToPrint ref={printRef}>
                                <Modelo1 abstract={selected} />
                            </ComponentToPrint>
                        </div>
                        <div className='w-full bg-white rounded-lg p-4'>
                            <div className='w-full flex flex-col gap-4'>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Titulo</p>
                                    <p className='text-sm' align="justify">{selected.title}</p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full flex flex-row gap-4'>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Apresentação</p>
                                        <p className='text-sm'>{selected.presentation?.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Categoria</p>
                                        <p className='text-sm'>{selected.category?.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Sub-Categoria</p>
                                        <p className='text-sm'>{selected.subcategory?.name}</p>
                                    </div>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Autor</p>
                                    <p className='text-sm'>{selected.author?.first_user_name} {selected.author?.last_user_name}</p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Co-Autores</p>
                                    <div className='w-full flex flex-col gap-4'>
                                        <Table size='small' dataSource={selected.coauthor} rowKey={(record, index) => index} footer={null}
                                               pagination={false}>
                                            <Table.Column title="Nome" dataIndex="name" key="name"/>
                                            <Table.Column title="Instituição" dataIndex="institution" key="institution"/>
                                        </Table>
                                    </div>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Aprovação ética</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>
                                        {selected.ethic ? <Tag>Sim</Tag> : <Tag>Não</Tag>}
                                    </p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Introdução</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.introduction}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Resumo do caso</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.resume}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Discussão</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.discussion}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Conclusão</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.conclusion}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Palavras Chave</p>
                                    <p className='text-sm rounded-md'
                                       style={{border: 'none'}}>{selected?.keyword?.map((item, index) => <Tag
                                        key={item.id}>{item.name}</Tag>)} </p>
                                </div>
                            </div>
                            <div className='w-full flex flex-row gap-4 justify-between items-center mt-4'>
                                <Button
                                    htmlType="submit"
                                    loading={loading}
                                    className='ml-auto font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                                    onClick={() => setModalReprove(true)}
                                >
                                    Invalidar
                                </Button>
                                <Button
                                    htmlType="submit"
                                    loading={loading}
                                    style={{ backgroundColor: '#00728a', borderColor: '#00728a' }}
                                    className='text-white font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                                    onClick={() => setModalAprove(true)}
                                >
                                    Validar
                                </Button>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title={"Resumo"}
                    visible={modalView1}
                    onCancel={() => setModalView1(false)}
                    footer={null}
                    width={800}
                >
                    <div className='w-full'>
                        <div className='w-full flex flex-row justify-between items-center mb-0'>
                            <p className='text-sm font-bold mb-2'>Pré-Visualização</p>
                            <ReactToPrint
                                trigger={() => (
                                    <Button className='flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600' onClick={() => setModalView1(false)}>
                                        <FaDownload className='mr-2' />Baixar
                                    </Button>
                                )}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='hidden'>
                            <ComponentToPrint ref={printRef}>
                                <Modelo1 abstract={selected} />
                            </ComponentToPrint>
                        </div>
                        <div className='w-full bg-white rounded-lg p-4'>
                            <div className='w-full flex flex-col gap-4'>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Titulo</p>
                                    <p className='text-sm' align="justify">{selected.title}</p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full flex flex-row gap-4'>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Apresentação</p>
                                        <p className='text-sm'>{selected.presentation?.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Categoria</p>
                                        <p className='text-sm'>{selected.category?.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Sub-Categoria</p>
                                        <p className='text-sm'>{selected.subcategory?.name}</p>
                                    </div>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Autor</p>
                                    <p className='text-sm'>{selected.author?.first_user_name} {selected.author?.last_user_name}</p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Co-Autores</p>
                                    <div className='w-full flex flex-col gap-4'>
                                        <Table size='small' dataSource={selected.coauthor} rowKey={(record, index) => index} footer={null}
                                               pagination={false}>
                                            <Table.Column title="Nome" dataIndex="name" key="name"/>
                                            <Table.Column title="Instituição" dataIndex="institution" key="institution"/>
                                        </Table>
                                    </div>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Aprovação ética</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>
                                        {selected.ethic ? <Tag>Sim</Tag> : <Tag>Não</Tag>}
                                    </p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Introdução</p>
                                    <p className='text-sm rounded-md' align="justify" style={{border: 'none'}}>{selected.introduction}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Resumo do caso</p>
                                    <p className='text-sm rounded-md' align="justify" style={{border: 'none'}}>{selected.resume}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Discussão</p>
                                    <p className='text-sm rounded-md' align="justify" style={{border: 'none'}}>{selected.discussion}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Conclusão</p>
                                    <p className='text-sm rounded-md' align="justify" style={{border: 'none'}}>{selected.conclusion}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Palavras Chave</p>
                                    <p className='text-sm rounded-md'
                                       style={{border: 'none'}}>{selected?.keyword?.map((item, index) => <Tag
                                        key={item.id}>{item.name}</Tag>)} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    title="Validar Resumo"
                    centered
                    visible={modalAprove}
                    onOk={putValidate}
                    onCancel={() => setModalAprove(false)}
                    okButtonProps={{ style: { backgroundColor: '#00728a', borderColor: '#00728a' } }}
                    okText="Confirmar"
                >
                    <p>Tem certeza que deseja validar este resumo?</p>
                </Modal>
                <Modal
                    title="Invalidar Resumo"
                    centered
                    visible={modalReprove}
                    onOk={handleInvalidate}
                    onCancel={() => setModalReprove(false)}
                    key={modalKey}
                    okButtonProps={{ style: { backgroundColor: '#00728a', borderColor: '#00728a' } }}
                    okText="Confirmar"
                >
                    <p>Tem certeza que deseja invalidar este resumo?</p>
                    <Form.Item
                        name="motivo"
                        label="Motivo"
                        className='mb-2'
                        rules={[{ required: true, message: 'Por favor insira o motivo' }]}
                    >
                        <Input.TextArea
                            placeholder='Insira o motivo'
                            value={comment}
                            onChange={(e) => setComment(e.target.value)}
                        />
                    </Form.Item>
                </Modal>
                <Modal
                    title={"Alocar Revisor"}
                    visible={modalRev}
                    onCancel={() => setModalRev(false)}
                    footer={null}
                    width={600}
                >
                    <div className='w-full'>
                        <p className='text-sm font-bold mb-2'>Titulo</p>
                        <p className='text-sm'>{selected.title}</p>
                    </div>
                    <div className='w-full'>
                        <p className='text-sm font-bold mb-2'>Autor</p>
                        <p className='text-sm'>{selected.author?.first_user_name} {selected.author?.last_user_name}</p>
                    </div>
                    <Form.Item
                        label="Revisor"
                        className='mb-2'
                        rules={[{ required: true, message: 'Por favor selecione o revisor' }]}
                    >
                        <Select
                            showSearch
                            mode="multiple"
                            placeholder="Selecione o revisor"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={selectedReviewer}
                            onChange={(value) => setSelectedReviewer(value)}
                        >
                            {reviewers.map((item, index) => (
                                <Select.Option key={item.id} value={item.id}>{item.first_user_name} {item.last_user_name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className='w-full flex flex-row gap-4 justify-between items-center mt-4'>
                        <Button
                            htmlType="submit"
                            loading={loading}
                            onClick={alocarReviewer}
                            className='ml-auto bg-blue-600 text-white font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                        >
                            Alocar
                        </Button>
                    </div>
                </Modal>
                <Modal
                    title={"Realocar Revisor"}
                    visible={modalEditRev}
                    onCancel={() => setModalEditRev(false)}
                    footer={null}
                    width={500}
                >
                    <div className='w-full'>
                        <p className='text-sm font-bold mb-2'>Titulo</p>
                        <p className='text-sm'>{selected.title}</p>
                    </div>
                    <div className='w-full'>
                        <p className='text-sm font-bold mb-2'>Autor</p>
                        <p className='text-sm'>{selected.author?.first_user_name} {selected.author?.last_user_name}</p>
                    </div>
                    <Form.Item
                        label="Revisor"
                        className='mb-2'
                        rules={[{ required: true, message: 'Por favor selecione o revisor' }]}
                    >
                        <Select
                            showSearch
                            mode='multiple'
                            placeholder="Selecione o revisor"
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }
                            value={selectedReviewer}
                            onChange={(value) => setSelectedReviewer(value)}
                        >
                            {reviewers.map((item, index) => (
                                <Select.Option key={item.id} value={item.id}>{item.first_user_name} {item.last_user_name}</Select.Option>
                            ))}
                        </Select>
                    </Form.Item>
                    <div className='w-full flex flex-row gap-4 justify-between items-center mt-4'>
                        <Button
                            htmlType="submit"
                            loading={loading}
                            className='ml-auto bg-blue-600 text-white font-bold m-0 flex-row items-center flex justify-center gap-2 px-5'
                            onClick={reAlocarReviewer}
                        >
                            Realocar
                        </Button>
                    </div>
                </Modal>

            </div>
        </div>
    );
}

export default ListSummary1;