import React, {useState, useEffect, useRef} from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { FaSearch, FaNotesMedical, FaPlus, FaEye, FaDownload, FaArrowRight } from 'react-icons/fa';
import { MdPeople } from 'react-icons/md';
import { Input, Button, Form, Space, Table, Divider, notification, Modal, Tag, Row, Col, Menu, Select } from 'antd';
import {CheckOutlined, EyeOutlined} from '@ant-design/icons';
import dayJS from "dayjs";
import ReactToPrint from "react-to-print";
import {ComponentToPrint} from "../../Components/Form/print";
import Modelo1 from "../../Components/Form/modelo1";

function HistSummary1() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [modalView, setModalView] = useState(false);
    const [selected, setSelected] = useState({});
    const [selectedKey, setSelectedKey] = useState('1')
    const [modalKey, setModalKey] = useState(Date.now());
    const printRef = useRef();

    const [searchValue, setSearchValue] = useState({
        nid: '',
        nome: '',
        contacto: '',
    });

    const [status, setStatus] = useState(null);

    useEffect(() => {
        localStorage.setItem('title', 'Histórico de Resumos');
        localStorage.setItem('type', '2');
        getResumes("Invalidado");
    }, [])

    const goView = (record) => {
        try {
            setSelected(record);
            setModalKey(Date.now());
        } finally {
            setModalView(true);
        }
    }


    //Em Validacao
    const columnsMarked = [
        {
            title: "ID",
            dataIndex: "id",
            width: 90,
            key: "id",
        },
        {
            title: "Titulo do Resumo",
            dataIndex: "title",
        },
        {
            title: "Autor do Resumo",
            dataIndex: "author",
            render: (text, record) => (
                <p>{record.author.first_user_name} {record.author.last_user_name}</p>
            ),
        },
        {
            title: "Data de Submissão",
            dataIndex: "date",
            width: 160,
            render: (text, record) => (
                <p>{dayJS(record.created_at).format('DD/MM/YYYY ')}</p>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
            width: 130,
            render: (text, record) => (
                <div>
                    <Tag color={record.status === "Em validação" ? "orange" : "green"} key={record.status}>
                        {record.status.name}
                    </Tag>
                </div>
            ),
        },
        // {
        //     title: "Acções",
        //     key: "actions",
        //     width: 90,
        //     align: "center",
        //     render: (text, record) => (
        //         <Space size="middle">
        //             <Button size="small" style={{ backgroundColor: '#00728a' }} className="text-white flex items-center" type="link" onClick={() => goView(record)}>
        //                 <CheckOutlined className='text-white' size={35} />
        //             </Button>
        //         </Space>
        //     ),
        // },
    ];

    const [dataMarked, setDataMarked] = useState([]);

    const filtered = (data) => {
        return data.filter((item) => {
            return (
                item.title.toLowerCase().includes(searchValue.nome.toLowerCase()) ||
                `${item.author.first_user_name} ${item.author.last_user_name}`.toLowerCase().includes(searchValue.nome.toLowerCase())
            );
        });
    }

    const fetchResumes = async (status_id) => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resume/clinical/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                },
                params: {
                    status: status_id
                }
            });
            setDataMarked(response.data);
        } catch (error) {
            setDataMarked([]);
            notification.error({
                message: 'Erro',
                description: 'Falha ao carregar resumos'
            });
        }
    }

    const getResumes = async (status) => {
        try {
            setLoading(true);
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/api/resume/status/`, {
                headers: {
                    Authorization: `Token ${sessionStorage.getItem('token')}`
                }
            });
            const sid = response.data.filter((item) => item.name === status);
            if (sid !== undefined && sid.length !== 0)
                fetchResumes(sid[0].id);
            else
                setDataMarked([]);
        } catch (error) {
            setLoading(false);
            setDataMarked([]);
            notification.error({
                message: 'Erro',
                description: 'Falha ao carregar estados de resumos'
            });
        } finally {
            setLoading(false);
        }
    }

    const countWords = (text) => {
        return text?.split(' ').filter(word => word).length;
    }

    const handleMenuClick = e => {
        setSelectedKey(e.key);
        if (e.key == '1') {
            getResumes("Invalidado");
        } else if (e.key == '2') {
            getResumes("Reprovado");
        } else if (e.key == '3') {
            getResumes("Aprovado - Com modificações ligeiras");
        } else if (e.key == '4') {
            getResumes("Aprovado - Sem modificações");
        }
    }

    return (
        <div className='flex flex-col gap-4'>
            <Menu
                mode="horizontal"
                className='rounded-lg pb-1'
                selectedKeys={[selectedKey]}
                onClick={handleMenuClick}
            >
                <Menu.Item key="1" className='hover:bg-blue-500'>
                    Invalidado
                </Menu.Item>
                <Menu.Item key="2" className='hover:bg-blue-500'>
                    Reprovado
                </Menu.Item>
                <Menu.Item key="3" className='hover:bg-blue-500'>
                    Aprovado - Com modificações ligeiras
                </Menu.Item>
                <Menu.Item key="4" className='hover:bg-blue-500'>
                    Aprovado - Sem modificações
                </Menu.Item>
            </Menu>
            <div className="flex flex-col gap-2 bg-white rounded-lg p-4">
                <div className="flex flex-col items-center sm:flex-row gap-2">
                    <FaSearch />
                    <Input
                        placeholder="Pesquisa por Nome"
                        value={searchValue.nome}
                        className='sm:w-64'
                        onChange={(e) => setSearchValue({ ...searchValue, nome: e.target.value })}
                    />
                </div>
                <Divider className='my-0' />
                {selectedKey == '1' &&
                    <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                        <Table
                            size='small'
                            columns={columnsMarked}
                            dataSource={filtered(dataMarked)}
                            loading={loading}
                            pagination={{pageSize: 5}}
                        />
                    </div>
                }
                {selectedKey == '2' &&
                    <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                        <Table
                            size='small'
                            columns={columnsMarked}
                            dataSource={filtered(dataMarked)}
                            loading={loading}
                            pagination={{pageSize: 5}}
                        />
                    </div>
                }
                {selectedKey == '3' &&
                    <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                        <Table
                            size='small'
                            columns={columnsMarked}
                            dataSource={filtered(dataMarked)}
                            loading={loading}
                            pagination={{pageSize: 5}}
                        />
                    </div>
                }
                {selectedKey == '4' &&
                    <div style={{maxWidth: '100%', overflowX: 'auto'}}>
                        <Table
                            size='small'
                            columns={columnsMarked}
                            dataSource={filtered(dataMarked)}
                            loading={loading}
                            pagination={{pageSize: 5}}
                        />
                    </div>
                }
                <Modal
                    title={"Resumo"}
                    visible={modalView}
                    onCancel={() => setModalView(false)}
                    footer={null}
                    width={800}
                >
                    <div className='w-full'>
                        <div className='w-full flex flex-row justify-between items-center mb-0'>
                            <p className='text-sm font-bold mb-2'>Pré-Visualização</p>
                            <ReactToPrint
                                trigger={() => (
                                    <Button
                                        className='flex items-center bg-blue-500 text-white cursor-pointer hover:bg-blue-600'
                                        onClick={() => setModalView(false)}>
                                        <FaDownload className='mr-2'/>Baixar
                                    </Button>
                                )}
                                content={() => printRef.current}
                            />
                        </div>
                        <div className='hidden'>
                            <ComponentToPrint ref={printRef}>
                                <Modelo1 abstract={selected}/>
                            </ComponentToPrint>
                        </div>
                        <div className='w-full bg-white rounded-lg p-4'>
                            <div className='w-full flex flex-col gap-4'>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Titulo</p>
                                    <p className='text-sm' align="justify">{selected.title}</p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full flex flex-row gap-4'>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Apresentação</p>
                                        <p className='text-sm'>{selected.presentation?.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Categoria</p>
                                        <p className='text-sm'>{selected.category?.name}</p>
                                    </div>
                                    <div className='w-full'>
                                        <p className='text-sm font-bold mb-2'>Sub-Categoria</p>
                                        <p className='text-sm'>{selected.subcategory?.name}</p>
                                    </div>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Autor</p>
                                    <p className='text-sm'>{selected.author?.first_user_name} {selected.author?.last_user_name}</p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Co-Autores</p>
                                    <div className='w-full flex flex-col gap-4'>
                                        <Table size='small' dataSource={selected.coauthor}
                                               rowKey={(record, index) => index} footer={null}
                                               pagination={false}>
                                            <Table.Column title="Nome" dataIndex="name" key="name"/>
                                            <Table.Column title="Instituição" dataIndex="institution"
                                                          key="institution"/>
                                        </Table>
                                    </div>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Aprovação ética</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>
                                        {selected.ethic ? <Tag>Sim</Tag> : <Tag>Não</Tag>}
                                    </p>
                                </div>
                                <Divider className='my-0'/>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Introdução</p>
                                    <p className='text-sm rounded-md'
                                       style={{border: 'none'}}>{selected.introduction}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Resumo do caso</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.resume}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Discussão</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.discussion}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Conclusão</p>
                                    <p className='text-sm rounded-md' style={{border: 'none'}}>{selected.conclusion}</p>
                                </div>
                                <div className='w-full'>
                                    <p className='text-sm font-bold mb-2'>Palavras Chave</p>
                                    <p className='text-sm rounded-md'
                                       style={{border: 'none'}}>{selected?.keyword?.map((item, index) => <Tag
                                        key={item.id}>{item.name}</Tag>)} </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        </div>
    );
}

export default HistSummary1;